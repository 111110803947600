//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  productsSlider()
  gallerySlider()
}

function productsSlider() {
  if ($('.js-slider-products').length) {
    var inst = $('.js-slider-products'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: false,
      speed: 600,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: true,
      // },
      breakpoints: {
        200: {
          slidesPerView: 1,
					spaceBetween: 15,
        },
        576: {
          slidesPerView: 2,
					spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
					spaceBetween: 20,
        },
        992: {
					spaceBetween: 30,
          slidesPerView: 3,
        },
        1200: {
					spaceBetween: 30,
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })
  }
}

function gallerySlider() {
  if ($('.js-slider-gallery').length) {
    var inst = $('.js-slider-gallery'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: true,
      // },
      breakpoints: {
        200: {
					spaceBetween: 15,
          slidesPerView: 1,
        },
        576: {
					spaceBetween: 15,
          slidesPerView: 2,
        },
        768: {
					spaceBetween: 20,
          slidesPerView: 2,
        },
        992: {
					spaceBetween: 30,
          slidesPerView: 3,
        },
        1200: {
					spaceBetween: 30,
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })
  }
}